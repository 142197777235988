import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { ThemeProvider } from "styled-components"

import { defaultTheme, GlobalStyle } from "../theme/default"

export class Layout extends React.Component {
  static propTypes = {
    loadUikit: PropTypes.bool,
    meta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props)
    if (props.loadUikit) {
      import("uikit/dist/js/uikit").then((uikit) => {
        this.uikit = uikit.default
        import("uikit/dist/js/uikit-icons").then((uikitIcons) => {
          this.uikitIcons = uikitIcons.default
          this.uikit.use(uikitIcons.default)
        })
      })
    }
  }

  render() {
    let title = ""
    const meta = [
      {
        property: "og:type",
        content: "website",
      },
    ]
    const link = []
    if (this.props.meta) {
      if (this.props.meta.url) {
        // meta.push({
        //   property: "url",
        //   content: this.props.meta.url,
        // })
        meta.push({
          property: "og:url",
          content: this.props.meta.url,
        })
        link.push({
          rel: "canonical",
          href: this.props.meta.url,
        })
      }
      if (this.props.meta.title) {
        title = this.props.meta.title
        // meta.push({
        //   property: "name",
        //   content: this.props.meta.title,
        // })
        meta.push({
          property: "og:title",
          content: this.props.meta.title,
        })
      }
      if (this.props.meta.description) {
        meta.push({
          name: "description", // wc html
          // property: "description", // schema.org combined
          content: this.props.meta.description,
        })
        meta.push({
          property: "og:description",
          content: this.props.meta.description,
        })
      }
      if (this.props.meta.imageRootUrl && this.props.meta.image) {
        // meta.push({
        //   property: "image",
        //   content: `${this.props.meta.imageRootUrl}${this.props.meta.image}`,
        // })
        meta.push({
          property: "og:image",
          content: `${this.props.meta.imageRootUrl}${this.props.meta.image}`,
        })
      }
    }
    return (
      <ThemeProvider theme={defaultTheme}>
        <>
          <Helmet title={title} meta={meta} link={link}>
            <html
              lang="en"
              prefix="og: http://ogp.me/ns#"
              vocab="http://schema.org/"
              typeof="schema:WebPage"
            />
          </Helmet>
          <GlobalStyle />
          {this.props.children}
        </>
      </ThemeProvider>
    )
  }
}
