import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import { Container } from "./Container"
import { Section } from "./Section"

const FooterSection = styled(Section)`
  background-color: rgba(35, 36, 103, 0.8);
`

const FooterText = styled.span`
  color: white;
`

export const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        allDataJson {
          edges {
            node {
              components {
                footer {
                  copy
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const footer = data.allDataJson.edges[0].node.components.footer
      return (
        <FooterSection>
          <Container>
            <FooterText>{footer.copy}</FooterText>
          </Container>
        </FooterSection>
      )
    }}
  />
)
