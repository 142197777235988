import styled from "styled-components"

export const smallVPadding = 40
export const largeVPadding = 70

export const Section = styled.div`
  padding: ${smallVPadding}px 0;
  @media all and (min-width: 960px) {
    padding: ${largeVPadding}px 0;
  }
`
