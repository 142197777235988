import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link, StaticQuery, graphql } from "gatsby"
import { Drawer } from "antd"

import { Section } from "./Section"
import lockIcon from "../images/lock-dark.svg"

const baseSize = 16
export const height = 80
const logoHeight = 70 // If you change this, make sure to update the logo width in 'graphql childImageSharp' also
const logoWidth = (2188 / 809) * logoHeight
const paddingV = (height - logoHeight) / baseSize / 2
const headerZIndex = 2000
const drawerZIndex = 10000

const StyledSection = styled(Section)`
  & {
    padding: 0;
  }

  /* Fixed position header */
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${headerZIndex};
  box-shadow: 0 0 3px 0 #ccc;
  & + * {
    margin-top: ${height}px;
  }
`

const Container = styled.div`
  height: ${height / baseSize}rem;
  padding: ${paddingV}rem 15px;
  @media all and (min-width: 640px) {
    padding: ${paddingV}rem 30px;
  }
  @media all and (min-width: 960px) {
    padding: ${paddingV}rem 40px;
  }
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
`

const Logo = styled(Img)`
  flex: 0 0 ${logoWidth}px;
`

const Menu = styled.div`
  flex: 1 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-content: center;
`

const menuIconBreakpoint = 640

const MenuItem = styled(Link).attrs({
  className: "showMenuItem",
})`
  margin: 0 0 0 1.2rem;
  line-height: ${logoHeight / baseSize}rem;
  box-sizing: border-box;
  vertical-align: middle;
  color: #232467;
  &:hover {
    color: #232467bb;
  }
  display: none;
  @media all and (min-width: ${menuIconBreakpoint / baseSize}rem) {
    display: inline-block;
  }
`

const MenuIcon = styled.button.attrs({
  className: "hideMenuIcon",
})`
  cursor: pointer;
  background: none;
  border: none;
  display: inline-block;
  @media all and (min-width: ${menuIconBreakpoint / baseSize}rem) {
    display: none;
  }
`

const VMenuItem = styled.div`
  margin: 0;
  line-height: ${50 / baseSize}rem;
  box-sizing: border-box;
  cursor: pointer;
  color: #232467;
  &:hover {
    color: #232467bb;
    text-decoration: underline;
  }
  display: block;
`

export class Header extends React.Component {
  state = { drawerVisible: false }
  showDrawer = () => {
    this.setState({
      drawerVisible: true,
    })
  }
  onCloseDrawer = () => {
    this.setState({
      drawerVisible: false,
    })
  }
  vMenuItemClick = (link) => {
    this.onCloseDrawer()
    setTimeout(() => {
      if (
        typeof window !== "undefined" &&
        window.location &&
        typeof link.startsWith === "function" &&
        !link.startsWith("http") // make sure link within same domain
      ) {
        window.location.href = link
      }
    }, 500)
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allDataJson {
              edges {
                node {
                  components {
                    header {
                      logo {
                        childImageSharp {
                          fixed(width: 189, traceSVG: { optTolerance: 0.01 }) {
                            ...GatsbyImageSharpFixed_tracedSVG
                          }
                        }
                      }
                      menu {
                        name
                        link
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const header = data.allDataJson.edges[0].node.components.header
          if (header.menu[header.menu.length - 1].link !== "/login") {
            header.menu.push({
              name: (
                <span>
                  <img
                    src={lockIcon}
                    width="12px"
                    height="16px"
                    alt="Login"
                    style={{ margin: "0 0.5rem 0.4rem 0" }}
                  />
                  Login
                </span>
              ),
              link: "/login",
            })
          }
          return (
            <StyledSection>
              <Container>
                <Link to="/">
                  <Logo fixed={header.logo.childImageSharp.fixed} />
                </Link>
                <Menu>
                  {header.menu.map((item) => (
                    <MenuItem key={item.link} to={item.link}>
                      {item.name}
                    </MenuItem>
                  ))}
                  <MenuIcon onClick={this.showDrawer}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="9" width="20" height="2" />
                      <rect y="3" width="20" height="2" />
                      <rect y="15" width="20" height="2" />
                    </svg>
                  </MenuIcon>
                  <Drawer
                    placement="left"
                    onClose={this.onCloseDrawer}
                    visible={this.state.drawerVisible}
                    zIndex={drawerZIndex}
                  >
                    {header.menu.map((item) => (
                      <VMenuItem
                        key={item.link}
                        onClick={() => this.vMenuItemClick(item.link)}
                      >
                        {item.name}
                      </VMenuItem>
                    ))}
                  </Drawer>
                </Menu>
              </Container>
            </StyledSection>
          )
        }}
      />
    )
  }
}
