import { createGlobalStyle } from "styled-components"
import "./site.scss"

export const GlobalStyle = createGlobalStyle`
  html {
    overflow-y: scroll;
    /* Overwrite UIKit font size change on bigger screen */
    font-size: 100%;
  }
`

const baseColorGray = "#e5e5e5"
const orange = "#F57C01"

export const defaultTheme = {
  baseColorGray,
  siteBgColor: "#000",
  borderColor: baseColorGray,
  border: "1px solid " + baseColorGray,
  ascent: orange,
  baseSize: 16,
}
