import styled, { css } from "styled-components"

const convertSizeFromStringToPx = (size) => {
  if (size.endsWith("px")) {
    return parseFloat(size.replace("px", ""))
  }
  if (
    size.endsWith("vw") &&
    typeof document !== "undefined" &&
    document.documentElement
  ) {
    return (
      (parseFloat(size.replace("vw", "")) *
        document.documentElement.clientWidth) /
      100
    )
  }
  // TODO: parse em or rem
  return undefined
}

const findUrl = (size, srcSet) => {
  const [url] = srcSet.reduce(([prevUrl, prevSize], [currUrl, currSize]) => {
    if (prevSize >= size) {
      if (currSize >= size) {
        if (prevSize < currSize) {
          return [prevUrl, prevSize]
        } else {
          return [currUrl, currSize]
        }
      } else {
        return [prevUrl, prevSize]
      }
    } else {
      if (currSize >= size) {
        return [currUrl, currSize]
      } else {
        // both smaller then the size required, choose the bigger one
        if (prevSize < currSize) {
          return [currUrl, currSize]
        } else {
          return [prevUrl, prevSize]
        }
      }
    }
  })
  return url
}

const backgroundImageFromSrcSet = (fluid) => {
  if (!fluid) {
    throw new Error("Missing fluid prop for FluidBackgroundImage")
  }
  let { src, srcSet, sizes } = fluid
  if (typeof srcSet !== "string" || typeof sizes !== "string") {
    return css`
      background-image: url(${src});
    `
  }

  let fluidImage = true
  srcSet = srcSet
    // Split by comma and trim
    .split(/\s*(?:,)\s*/)
    .map((ss) => {
      // split by space. E.g. "elva-fairy-320w.jpg 320w"
      let [url, size] = ss.split(/[ ]/)
      if (size && size.endsWith("w")) {
        size = parseFloat(size.replace("w", ""))
      } else {
        // Size end with "x" is for a fixed image
        fluidImage = false
      }
      return [url, size]
    })

  if (!fluidImage) {
    return css`
      background-image: url(${src});
    `
  }

  let cssStr = ""
  // 1. For each sizes, create media query
  // 2. Use width from the sizes to get background URL from srcSet
  // https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
  sizes
    // Split by comma and trim
    .split(/\s*(?:,)\s*/)
    .reverse()
    .forEach((s) => {
      // split by last space. E.g. "(max-width: 480px) 440px" or just "800px"
      let [media, size] = s.split(/[ ](?=[^ ]+$)/)
      if (typeof size === "undefined") {
        size = media
        media = undefined
      }
      size = convertSizeFromStringToPx(size)
      if (size) {
        const url = findUrl(size, srcSet)
        if (media) {
          cssStr += `@media all and ${media} {
            background-image: url(${url});
          }`
        } else {
          cssStr += `background-image: url(${url});`
        }
      }
    })
  return css([cssStr])
}

export const FluidBackgroundImage = styled.div`
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  ${({ fluid }) => backgroundImageFromSrcSet(fluid)};
`
