import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Carousel, Tabs } from "antd"

import { Header } from "../../components/Header"
import { Layout } from "../../components/layout"
import { Footer } from "../../components/Footer"
import { Section } from "../../components/Section"
import { Container } from "../../components/Container"
import { Content } from "../../components/text/Content"
import { P } from "../../components/text/P"
import { FluidBackgroundImage } from "../../components/FluidBackgroundImage"

const CenteredContent = styled(Content)`
  margin-left: auto;
  margin-right: auto;
`

const H1White = styled.h1`
  text-align: center;
  color: white;
`

const H2 = styled.h2`
  color: ${(props) => props.theme.ascent};
`

const SlideshowSection = styled(Section)`
  && {
    margin: 0;
    padding: 0;
    background-color: gray;
  }
`

const SlideshowMenuContainer = styled.div`
  margin: 0;
  padding: 0;
`

const StyledTabs = styled(Tabs)`
  && {
    .ant-tabs-bar {
      margin-bottom: 0;
    }
    .ant-tabs-tab:hover {
      color: black;
    }
    .ant-tabs-tab-active {
      color: white;
    }
    .ant-tabs-ink-bar {
      background-color: white;
    }
    .ant-tabs-nav-scroll {
      /* The child tabs (.ant-tabs-nav) is inline-block */
      text-align: center;
    }
  }
`

const CarouselContainer = styled.div`
  margin: 0;
  padding: 0;
  @media all and (orientation: portrait) {
    padding: 0;
  }
  @media all and (orientation: landscape) {
    padding: 0 20vw;
  }
`

class Page extends React.Component {
  constructor(props) {
    super(props)
    const page =
      props.data.allDataJson.edges[0].node.pages.services_repair_maintenance
    this.state = { selectedSlideshow: Object.keys(page.slideshow)[0] }

    this.tabChange = this.tabChange.bind(this)
    this.slideshowSwipe = this.slideshowSwipe.bind(this)
    this.slideshowDotsClick = this.slideshowDotsClick.bind(this)
    this.beforeSlideshowChange = this.beforeSlideshowChange.bind(this)
    this.moveNextMenu = this.moveNextMenu.bind(this)

    this.carousel = React.createRef()
    this.carouselIndex = 0
  }

  tabChange(activeKey) {
    // console.log("tabChange", activeKey)
    this.setState({ selectedSlideshow: activeKey })
    if (
      this.carousel.current &&
      typeof this.carousel.current.goTo === "function"
    ) {
      this.carouselForceGoTo = true
      // console.log("goto 0", this.carousel.current)
      this.carousel.current.goTo(0)
    }
  }

  slideshowSwipe(direction) {
    // console.log("swipe", direction, this.carouselIndex)
    if (direction === "right" && this.carouselIndex === 1) {
      // prevent tab change on swipe back to 0
      this.carouselForceGoTo = true
    }
  }

  slideshowDotsClick(i) {
    return (
      <button
        onMouseDown={() => {
          if (i === 0) {
            // prevent tab change when click the first dot
            this.carouselForceGoTo = true
          }
        }}
      >
        {i + 1}
      </button>
    )
  }

  beforeSlideshowChange(from, to) {
    // console.log("before change: now, to", this.state.selectedSlideshow, to)
    this.carouselIndex = to
    if (to === 0) {
      if (this.carouselForceGoTo === true) {
        this.carouselForceGoTo = false
      } else {
        this.moveNextMenu()
      }
    }
  }

  moveNextMenu() {
    const page = this.props.data.allDataJson.edges[0].node.pages
      .services_repair_maintenance
    const keys = Object.keys(page.slideshow)
    const currIndex = keys.indexOf(this.state.selectedSlideshow)
    let newSelectedSlideshow
    if (currIndex >= keys.length - 1) {
      newSelectedSlideshow = keys[0]
    } else {
      newSelectedSlideshow = keys[currIndex + 1]
    }
    // console.log(
    //   "->> move ",
    //   this.state.selectedSlideshow,
    //   "to",
    //   newSelectedSlideshow,
    // )
    setTimeout(
      () => this.setState({ selectedSlideshow: newSelectedSlideshow }),
      0,
    )
  }

  render() {
    const page = this.props.data.allDataJson.edges[0].node.pages
      .services_repair_maintenance
    return (
      <Layout meta={page.meta} loadUikit={true}>
        <Header />
        <FluidBackgroundImage fluid={page.title_bg.childImageSharp.fluid}>
          <Section>
            <Container>
              <H1White>{page.title}</H1White>
              <CenteredContent maxWidth={918}>
                <H2>{page.what_we_do.title}</H2>
                <P data={page.what_we_do.desc} />
                <H2>{page.what_make_us_difference.title}</H2>
                <P data={page.what_make_us_difference.desc} />
              </CenteredContent>
            </Container>
          </Section>
        </FluidBackgroundImage>
        <SlideshowSection>
          <SlideshowMenuContainer>
            <StyledTabs
              activeKey={this.state.selectedSlideshow}
              onChange={this.tabChange}
            >
              {Object.keys(page.slideshow).map((key) => (
                <Tabs.TabPane tab={page.slideshow[key].title} key={key} />
              ))}
            </StyledTabs>
          </SlideshowMenuContainer>
          <CarouselContainer>
            <Carousel
              key={this.state.selectedSlideshow}
              autoplay={true}
              adaptiveHeight={true}
              onSwipe={this.slideshowSwipe}
              customPaging={this.slideshowDotsClick}
              beforeChange={this.beforeSlideshowChange}
              ref={this.carousel}
            >
              {this.state.selectedSlideshow &&
              page.slideshow[this.state.selectedSlideshow]
                ? page.slideshow[this.state.selectedSlideshow].images.map(
                    (img, i) => (
                      <Img key={i} fluid={img.childImageSharp.fluid} />
                    ),
                  )
                : null}
            </Carousel>
          </CarouselContainer>
        </SlideshowSection>
        <Footer />
      </Layout>
    )
  }
}

export default Page

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          pages {
            services_repair_maintenance {
              meta {
                url
                title
                description
                imageRootUrl
                image
              }
              title
              title_bg {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              what_we_do {
                title
                desc
              }
              what_make_us_difference {
                title
                desc
              }
              slideshow {
                manufacturing {
                  title
                  images {
                    childImageSharp {
                      fluid(maxWidth: 1120, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                telecommunications {
                  title
                  images {
                    childImageSharp {
                      fluid(maxWidth: 1120, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                education {
                  title
                  images {
                    childImageSharp {
                      fluid(maxWidth: 1120, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                government {
                  title
                  images {
                    childImageSharp {
                      fluid(maxWidth: 1120, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                broadcasting {
                  title
                  images {
                    childImageSharp {
                      fluid(maxWidth: 1120, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                power_utility {
                  title
                  images {
                    childImageSharp {
                      fluid(maxWidth: 1120, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
