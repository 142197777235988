import styled, { css } from "styled-components"

export const getHPadding = (clientWidth) => {
  if (clientWidth < 640) {
    return 15
  } else if (clientWidth < 960) {
    return 30
  } else {
    return 40
  }
}

export const Container = styled.div`
  ${({ theme, vPadding, hPadding }) => {
    if (typeof vPadding !== "number") {
      vPadding = 0
    }
    if (typeof hPadding !== "number") {
      hPadding = 0
    }
    return css`
      padding: ${vPadding} ${(15 + hPadding) / theme.baseSize}rem;
      @media all and (min-width: ${640 / theme.baseSize}rem) {
        padding: ${vPadding} ${(30 + hPadding) / theme.baseSize}rem;
      }
      @media all and (min-width: ${960 / theme.baseSize}rem) {
        padding: ${vPadding} ${(40 + hPadding) / theme.baseSize}rem;
      }
    `
  }};
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${({ theme }) => 36 / theme.baseSize}rem;
  :last-child {
    margin-bottom: 0;
  }
`
