import React from "react"

/**
 * Create p tags from string array to construct paragraphs.
 */
export const P = ({ data, ...props }) => {
  if (!Array.isArray(data)) {
    return null
  }
  return data.map((value, i) => (
    <p key={i} {...props}>
      {value}
    </p>
  ))
}
